<form [formGroup]="form">
	<mat-card class="auth">
		<mat-card-header>
			<img ngSrc="assets/images/delete-account-icon.svg" alt="Delete Account" width="167" height="167" />

			<h3 class="heading-3-regular">{{ 'n127' | translations }}</h3>

			<p class="fs-14px lh-18px fw-400 fg-general-4 text-center mb-2">{{ 'n3218' | translations }}</p>

			<span clas="fs-14px fw-400 lh-18px fg-general-5">{{ email }}</span>
		</mat-card-header>

		<mat-card-content>
			<!-- OTP input -->
			<findqo-input
				wrapperClass="no-subscript"
				inputClass="text-center text-body-20px-medium"
				formControlName="pin"
				[maxLength]="6"
				type="text"
				pattern="\d*"
				[placeholder]="'n3232' | translations"
			></findqo-input>
		</mat-card-content>

		<mat-card-actions>
			<findqo-button
				type="submit"
				[btnType]="buttonType.FILLED"
				[label]="'n3219' | translations"
				[isLoading]="isLoading$ | async"
				(clicked)="onVerifyEmail()"
				[isStopPropagation]="true"
				[isDisabled]="form.controls['pin'].pristine || form.invalid"
				class="verfiy-email"
			></findqo-button>

			<div class="w-100 timer">
				<ng-container *ngIf="otpTimeRemaining$ | async as otpTimeRemaining; else resendCode">
					<span class="fs-14px lh-18px fw-400 fg-general-4">
						{{ 'n557' | translations }}
						{{ otpTimeRemaining >= startTime * 1000 ? startTime : (otpTimeRemaining | date : 'ss') }}
					</span>
				</ng-container>

				<ng-template #resendCode>
					<findqo-button
						class="resend lh-1"
						type="reset"
						[label]="'n271' | translations"
						[btnType]="buttonType.BASIC"
						(clicked)="onResendOtp()"
						[btnClass]="'fg-error-3'"
					></findqo-button>
				</ng-template>
			</div>
		</mat-card-actions>
	</mat-card>
</form>
