<ng-container [ngSwitch]="btnType">
	<ng-template *ngSwitchDefault [ngTemplateOutlet]="filled"></ng-template>
	<ng-template *ngSwitchCase="buttonType.STROKED" [ngTemplateOutlet]="stroked"></ng-template>
	<ng-template *ngSwitchCase="buttonType.BASIC" [ngTemplateOutlet]="basic"></ng-template>
	<ng-template *ngSwitchCase="buttonType.ICON" [ngTemplateOutlet]="icon"></ng-template>
</ng-container>

<!-- 
	Dynamic Button Content

	There are some scenarios on having a dynamic content will not work so we handle this by adding two ways 
	of passing dynamic content.
	1. Using ng-content
	2. Using ngTemplateOutlet
-->

<!-- FILLED -->
<ng-template #filled>
	<button
		[id]
		mat-flat-button
		class="c-btn c-btn--filled text-truncate"
		[color]="color"
		[ngClass]="btnClass"
		[class.disabled]="isDisabled"
		[class.disabled-ripple]="isDisableRipple"
		[class.flex-row-reverse]="iconSuffix"
		[class.badge-hidden]="!+badge"
		[disabled]="isDisabled"
		[disableRipple]="isDisableRipple"
		[attr.aria-label]="ariaLabel"
		[matBadge]="badge"
		(click)="onClick($event)"
		[type]="type"
	>
		<mat-icon
			*ngIf="iconPrefix"
			[svgIcon]="iconPrefix === 'view-photo' || iconPrefix === 'chevron' || iconPrefix === 'map' || iconPrefix === 'down-arrow' ? '' : iconPrefix"
		>
			@if (iconPrefix === 'view-photo') {
				<findqo-view-photo-icon></findqo-view-photo-icon>
			}

			@if (iconPrefix === 'chevron') {
				<findqo-chevron-icon></findqo-chevron-icon>
			}

			@if (iconPrefix === 'map') {
				<findqo-map-icon></findqo-map-icon>
			}

			@if (iconPrefix === 'down-arrow') {
				<findqo-arrow-down-icon></findqo-arrow-down-icon>
			}
		</mat-icon>
		<mat-icon *ngIf="iconSuffix" [svgIcon]="iconSuffix" [ngClass]="btnIconClass"></mat-icon>
		<mat-icon *ngIf="materialIconPrefix" [ngClass]="btnIconClass">{{ materialIconPrefix }}</mat-icon>
		<mat-icon *ngIf="materialIconSuffix" iconPositionEnd>
			{{ materialIconSuffix }}
		</mat-icon>

		{{ label }}
	</button>
</ng-template>

<!-- STROKED -->
<ng-template #stroked>
	<button
		[id]
		mat-stroked-button
		class="c-btn c-btn--stroked text-truncate"
		color="primary"
		[ngClass]="btnClass"
		[class.disabled]="isDisabled"
		[class.disabled-ripple]="isDisableRipple"
		[class.flex-row-reverse]="iconSuffix"
		[disabled]="isDisabled"
		[disableRipple]="isDisableRipple"
		[attr.aria-label]="ariaLabel"
		[type]="type"
		(click)="onClick($event)"
	>
		<mat-icon
			*ngIf="iconPrefix"
			[svgIcon]="iconPrefix === 'view-photo' || iconPrefix === 'chevron' || iconPrefix === 'map' || iconPrefix === 'down-arrow' ? '' : iconPrefix"
		>
			@if (iconPrefix === 'view-photo') {
				<findqo-view-photo-icon></findqo-view-photo-icon>
			}

			@if (iconPrefix === 'chevron') {
				<findqo-chevron-icon></findqo-chevron-icon>
			}

			@if (iconPrefix === 'map') {
				<findqo-map-icon></findqo-map-icon>
			}

			@if (iconPrefix === 'down-arrow') {
				<findqo-arrow-down-icon></findqo-arrow-down-icon>
			}
		</mat-icon>
		<mat-icon *ngIf="iconSuffix" [svgIcon]="iconSuffix" [ngClass]="btnIconClass"></mat-icon>
		<mat-icon *ngIf="materialIconPrefix" [ngClass]="btnIconClass">{{ materialIconPrefix }}</mat-icon>
		<mat-icon *ngIf="materialIconSuffix" iconPositionEnd>
			{{ materialIconSuffix }}
		</mat-icon>

		{{ label }}
	</button>
</ng-template>

<!-- BASIC -->
<ng-template #basic>
	<button
		[id]
		mat-button
		class="c-btn c-btn--basic text-truncate"
		color="primary"
		[ngClass]="btnClass"
		[class.disabled]="isDisabled"
		[class.disabled-ripple]="isDisableRipple"
		[class.flex-row-reverse]="iconSuffix"
		[disabled]="isDisabled"
		[disableRipple]="isDisableRipple"
		[attr.aria-label]="ariaLabel"
		[type]="type"
		(click)="onClick($event)"
	>
		<mat-icon
			*ngIf="iconPrefix"
			[svgIcon]="iconPrefix === 'view-photo' || iconPrefix === 'chevron' || iconPrefix === 'map' || iconPrefix === 'down-arrow' ? '' : iconPrefix"
		>
			@if (iconPrefix === 'view-photo') {
				<findqo-view-photo-icon></findqo-view-photo-icon>
			}

			@if (iconPrefix === 'chevron') {
				<findqo-chevron-icon></findqo-chevron-icon>
			}

			@if (iconPrefix === 'map') {
				<findqo-map-icon></findqo-map-icon>
			}

			@if (iconPrefix === 'down-arrow') {
				<findqo-arrow-down-icon></findqo-arrow-down-icon>
			}
		</mat-icon>
		<mat-icon
			*ngIf="iconSuffix"
			[svgIcon]="iconSuffix === 'view-photo' || iconSuffix === 'chevron' || iconPrefix === 'map' || iconPrefix === 'down-arrow' ? '' : iconSuffix"
			[ngClass]="btnIconClass"
		>
			@if (iconSuffix === 'view-photo') {
				<findqo-view-photo-icon></findqo-view-photo-icon>
			}

			@if (iconSuffix === 'chevron') {
				<findqo-chevron-icon></findqo-chevron-icon>
			}

			@if (iconPrefix === 'map') {
				<findqo-map-icon></findqo-map-icon>
			}

			@if (iconPrefix === 'down-arrow') {
				<findqo-arrow-down-icon></findqo-arrow-down-icon>
			}
		</mat-icon>
		<mat-icon *ngIf="materialIconPrefix" [ngClass]="btnIconClass">{{ materialIconPrefix }}</mat-icon>

		<mat-icon *ngIf="materialIconSuffix" iconPositionEnd>
			{{ materialIconSuffix }}
		</mat-icon>

		{{ label }}

		<ng-content select="[btnBasicContent]"></ng-content>

		<ng-container *ngIf="contentTemplateRef" [ngTemplateOutlet]="contentTemplateRef"></ng-container>
	</button>
</ng-template>

<!-- ICON -->
<ng-template #icon>
	<button
		[id]
		mat-icon-button
		class="c-btn--icon"
		color="primary"
		[ngClass]="btnClass"
		[class.disabled]="isDisabled"
		[class.disabled-ripple]="isDisableRipple"
		[disabled]="isDisabled"
		[disableRipple]="isDisableRipple"
		[attr.aria-label]="ariaLabel"
		[type]="type"
		(click)="onClick($event)"
	>
		<mat-icon
			*ngIf="iconPrefix"
			[svgIcon]="iconPrefix === 'view-photo' || iconPrefix === 'chevron' || iconPrefix === 'map' || iconPrefix === 'down-arrow' ? '' : iconPrefix"
		>
			@if (iconPrefix === 'view-photo') {
				<findqo-view-photo-icon></findqo-view-photo-icon>
			}

			@if (iconPrefix === 'chevron') {
				<findqo-chevron-icon></findqo-chevron-icon>
			}

			@if (iconPrefix === 'map') {
				<findqo-map-icon></findqo-map-icon>
			}

			@if (iconPrefix === 'down-arrow') {
				<findqo-arrow-down-icon></findqo-arrow-down-icon>
			}
		</mat-icon>
		<mat-icon *ngIf="materialIconPrefix" class="material-symbols-outlines">{{ materialIconPrefix }}</mat-icon>
	</button>
</ng-template>
