<form [formGroup]="form">
	<mat-card class="auth">
		<mat-card-header>
			<img ngSrc="/assets/images/update-email.svg" alt="Update account email " width="217" height="175" class="text-center" />

			<h3>{{ 'n105' | translations }}</h3>
			<p class="text-center">{{ 'n126' | translations }}</p>
		</mat-card-header>

		<mat-card-content>
			<findqo-input wrapperClass="no-subscript" formControlName="email" [placeholder]="'n105' | translations" type="email"></findqo-input>
		</mat-card-content>

		<mat-card-actions>
			<findqo-button
				[isLoading]="isLoading$ | async"
				[label]="'n91' | translations"
				(clicked)="onRequestOtp()"
				[btnType]="buttonType.FILLED"
				[isDisabled]="form.invalid"
			></findqo-button>
		</mat-card-actions>
	</mat-card>
</form>
