import { Component } from '@angular/core';

@Component({
	selector: 'findqo-view-photo-icon',
	standalone: false,
	template: `
		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
			<path
				d="M13.625 1.875H2.375C1.75 1.875 1.125 2.5 1.125 3.125V11.875C1.125 12.5625 1.6875 13.125 2.375 13.125H13.625C14.25 13.125 14.875 12.5 14.875 11.875V3.125C14.875 2.5 14.25 1.875 13.625 1.875ZM13.625 11.825C13.6125 11.8438 13.5875 11.8625 13.575 11.875H2.375V3.175L2.425 3.125H13.5688C13.5875 3.1375 13.6062 3.1625 13.6187 3.175V11.825H13.625ZM7.375 9.69375L5.8125 7.8125L3.625 10.625H12.375L9.5625 6.875L7.375 9.69375Z"
				fill="#D1D5DB"
			/>
		</svg>
	`,
	styleUrl: './view-photo-icon.component.scss',
})
export class ViewPhotoIconComponent {}
