import { Component, OnInit } from '@angular/core';
import { AuthType } from '@core/enums';
import { AbstractAuthComponent } from '@feature/auth/abstracts/auth.abstract';
import { AuthFacade } from '@feature/auth/auth.facade';

@Component({
	selector: 'findqo-change-email-v2',
	templateUrl: './change-email-v2.component.html',
	styleUrls: ['./change-email-v2.component.scss'],
})
export class ChangeEmailV2Component extends AbstractAuthComponent implements OnInit {
	constructor(private readonly authFacade: AuthFacade) {
		super();
	}

	onSubmit(): void {
		this.authFacade.changeEmailRequestOtp();

		this.changed.emit({
			authType: AuthType.UPDATE_EMAIL_OTP,
			email: this.email,
		});
	}

	ngOnInit(): void {}
}
