import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthApi } from '@core/data-access/apis/auth.api';
import { FiltersApi } from '@core/data-access/apis/filters.api';
import { ProfileApi } from '@core/data-access/apis/profile.api';
import { AuthService } from '@core/data-access/services/auth.service';
import { ConfigState } from '@core/data-access/states/config.state';
import { ProfileState } from '@core/data-access/states/profile.state';
import { FilterValueListEndpoint, StorageType, UpdateAction } from '@core/enums';
import {
	UpdateBusinessProfileRequest,
	ClientUser,
	FilterDropdownValue,
	FilterValue,
	UpdateProfilePhoneRequest,
	UploadPhotoResponse,
	Agents,
	CommonResponse,
	UpdateActionType,
	BusinessProfile,
	PaymentLink,
	SubscriptionInfo,
} from '@core/models';
import { Translations } from '@core/models/translations.model';
import { SnackService } from '@core/services/snack.service';
import { BehaviorSubject, Observable, catchError, combineLatest, finalize, firstValueFrom, map, of, switchMap, tap } from 'rxjs';
import { MyProfileChangeAvatarComponent } from './components/my-profile-change-avatar-dialog/my-profile-change-avatar.component';
import { Params, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { AgentsApi } from '@core/data-access/apis/agents.api';
import { AgentsState } from '@core/data-access/states/agents.state';
import { IntercomService } from '@core/services/intercom.service';
import { StorageService } from '@core/services/storage.service';
import { APP_ROUTE } from '@core/constants';
import { SubscriptionStatus } from '@core/enums/profile.enum';

@Injectable({ providedIn: 'root' })
export class MyProfileFacade {
	// TODO: Create a model in type 'any'
	// TODO: Add comments

	protected constructor(
		private agentsState: AgentsState,
		private authApi: AuthApi,
		private authService: AuthService,
		private profileApi: ProfileApi,
		private profileState: ProfileState,
		private snackService: SnackService,
		private dialog: MatDialog,
		private configState: ConfigState,
		private filtersApi: FiltersApi,
		private agentsApi: AgentsApi,
		private intercomService: IntercomService,
		private storageService: StorageService,
		private router: Router
	) {}

	readonly filterValueListEndpoint = FilterValueListEndpoint;

	private _countyList$ = new BehaviorSubject<FilterValue[]>([]);
	private _countyAreaList$ = new BehaviorSubject<FilterValue[]>([]);
	private _showBusinessAcc$ = new BehaviorSubject<boolean>(false);

	getShowBusinessAcc$(): Observable<boolean> {
		return this._showBusinessAcc$.asObservable();
	}

	setShowBusinessAcc(showSubscription: boolean): void {
		this._showBusinessAcc$.next(showSubscription);
	}

	getUser$(): Observable<ClientUser> {
		return this.profileState.user$;
	}

	getUser() {
		return this.profileState.getUser();
	}

	updateUser(userData: ClientUser): void {
		this.profileState.updateUser(userData);
	}

	setUser(userData: ClientUser): void {
		this.profileState.setUser(userData);
	}

	getIsLoading$(): Observable<boolean> {
		return this.profileState.isLoading$;
	}

	getTranslations$(): Observable<Translations> {
		return this.configState.translations$;
	}

	getCountyList$(): Observable<FilterValue[]> {
		return this._countyList$.asObservable();
	}

	getCountyAreaList$(): Observable<FilterValue[]> {
		return this._countyAreaList$.asObservable();
	}

	setCountyList(county): void {
		this._countyList$.next(county);
	}

	setCountyAreaList(countyArea): void {
		this._countyAreaList$.next(countyArea);
	}

	loadCountyList(): void {
		this.filtersApi.getFilterValue(this.filterValueListEndpoint.COUNTY).subscribe((county) => {
			this.setCountyList(county.all);
		});
	}

	loadCountyAreaList(countyId: string): void {
		this.filtersApi.getCountyAreaList(countyId).subscribe((countyAreaList: FilterDropdownValue[]) => {
			this.setCountyAreaList(countyAreaList);
		});
	}

	getProfile(): Observable<ClientUser> {
		this.profileState.setIsLoading(true);

		return this.profileApi.getProfile().pipe(
			tap((response) => {
				this.profileState.updateUser(response.data);
			}),
			map((res) => res.data),
			finalize(() => {
				this.profileState.setIsLoading(false);
			})
		);
	}

	// Updating menu dependent keys
	updateProfileValues(key: string, action: UpdateActionType, value?: string | number) {
		const USER = { ...this.authService.user(), ...this.profileState.getUser() };

		switch (action) {
			case UpdateAction.ADD:
				this.profileState.updateUser({ ...USER, [key]: +USER[key] + 1 });
				this.authService.update({ ...USER, [key]: +USER[key] + 1 });
				break;

			case UpdateAction.REMOVE:
				const VALUE = +USER[key] - 1 >= 0 ? +USER[key] - 1 : 0;
				this.profileState.updateUser({ ...USER, [key]: +USER[key] - 1 });
				this.authService.update({ ...USER, [key]: +USER[key] - 1 });
				break;

			case UpdateAction.UPDATE:
				this.profileState.updateUser({ ...USER, [key]: value });
				this.authService.update({ ...USER, [key]: value });
				break;

			default:
		}
	}

	// pessimistic update
	// 1. call API
	// 2. update UI state
	loadUser(params?: Params): void {
		this.profileState.setIsLoading(true);

		this.profileApi
			.getProfile()
			.pipe(
				switchMap((response) => {
					this.profileState.updateUser(response.data);
					this.authService.update({ ...this.authService.user(), ...response.data });

					if (response.data?.businessProfile?.name) {
						let defaultParams = {
							page: 1,
							perPage: 100,
						};
						//to fetch agents of the user
						return this.agentsApi.getAgents({ ...defaultParams, ...(params || {}) });
					} else {
						return of({});
					}
				}),
				finalize(() => {
					this.profileState.setIsLoading(false);
				})
			)
			.subscribe((response: object | Agents[]) => {
				if (response instanceof Array) {
					this.agentsState.setAgents(response);
				} else {
					this.agentsState.setAgents([]);
				}
			});
	}

	// Used for auto-loggin-in the admin coming from the dashboard to the selected user profile
	autoLoginAdmin() {
		this.profileState.setIsLoading(true);

		this.profileApi
			.getProfile()
			.pipe(
				switchMap((response) => {
					this.profileState.updateUser(response.data);
					this.authService.update({ ...this.authService.user(), ...response.data });

					if (response.data?.businessProfile?.name) {
						//to fetch agents of the user
						return this.agentsApi.getAgents();
					} else {
						return of({});
					}
				}),
				finalize(() => {
					this.profileState.setIsLoading(false);
				})
			)
			.subscribe((response: object | Agents[]) => {
				if (response instanceof Array) {
					this.agentsState.setAgents(response);
				} else {
					this.agentsState.setAgents([]);
				}

				this.router.navigateByUrl('/profile');
			});
	}

	updateProfile(body: { name?: string; phone?: string; phoneCode?: string }): void {
		this.profileState.setIsLoading(true);

		this.profileApi
			.updateProfile(body)
			.pipe(
				catchError((error) => {
					this.snackService.open({
						data: { message: this.configState.getTranslations()['n612'], type: 'error' },
						panelClass: 'snackbar-error',
					});

					throw new Error(error);
				}),
				finalize(() => this.profileState.setIsLoading(false))
			)
			.subscribe(() => {
				const USER = { ...this.getUser(), ...body };
				this.updateUser(USER);

				let user = this.storageService.getItem(StorageType.USER);
				user = JSON.parse(user);

				//if incoming value is null keep the previous value
				user.name = body?.name ?? user.name;
				user.phone = body?.phone ?? user.phone;
				user.phoneCode = body?.phoneCode ?? user.phoneCode;

				this.storageService.setItem(StorageType.USER, JSON.stringify(user));
				this.intercomService.updateIntercomUser(USER);

				this.snackService.open({
					data: { message: body?.name ? this.configState.getTranslations()['n120'] : this.configState.getTranslations()['n3995'], type: 'success' },
					panelClass: 'snackbar-success',
				});
			});
	}

	updateProfilePhone(body: UpdateProfilePhoneRequest): void {
		this.profileState.setIsLoading(true);

		this.profileApi
			.updateProfilePhone(body)
			.pipe(
				switchMap(() => this.profileApi.getProfile()),
				finalize(() => this.profileState.setIsLoading(false))
			)
			.subscribe((response: CommonResponse<ClientUser>) => this.profileState.setUser(response.data));
	}

	login(body: any, dialogRef: MatDialogRef<any>): void {
		this.profileState.setIsLoading(true);

		this.authApi
			.login(body)
			.pipe(
				catchError((error) => {
					this.snackService.open({
						data: { message: 'There was problem logging you in. Please try again later.', type: 'error' },
						panelClass: 'snackbar-error',
					});

					throw new Error(error);
				}),
				finalize(() => this.profileState.setIsLoading(false))
			)
			.subscribe((response: any) => {
				this.authService.login(response.data);

				dialogRef.close(body);
			});
	}

	updateProfileConfirmEmail(body: any, type: string, dialogRef: MatDialogRef<any>): void {
		this.profileState.setIsLoading(true);

		this.profileApi
			.confirmEmailOtp(body)
			.pipe(
				catchError((error) => {
					this.snackService.open({
						data: { message: 'Code is incorrect. Please check and input the correct code.', type: 'error' },
						panelClass: 'snackbar-error',
					});

					throw new Error(error);
				}),
				finalize(() => this.profileState.setIsLoading(false))
			)
			.subscribe(() => {
				if (type === 'email') {
					this.snackService.open({
						data: { message: 'Email address Verified!', type: 'success' },
						panelClass: 'snackbar-success',
					});
				} else {
					this.snackService.open({
						data: { message: 'Email address updated!', type: 'success' },
						panelClass: 'snackbar-success',
					});
				}

				dialogRef.close();
			});
	}

	deleteProfile(params: Params, dialogRef: MatDialogRef<any>): void {
		this.profileState.setIsLoading(true);

		this.profileApi
			.deleteProfile(params)
			.pipe(
				catchError((error) => {
					this.snackService.open({
						data: { message: this.configState.getTranslations()['n3226'], type: 'error' },
						panelClass: 'snackbar-error',
					});
					throw new Error(error);
				}),
				finalize(() => this.profileState.setIsLoading(false))
			)
			.subscribe(() => {
				dialogRef.close(params);
				this.snackService.open({
					data: { message: this.configState.getTranslations()['n133'], type: 'error' },
					panelClass: 'snackbar-error',
				});
				this.authService.logout();
			});
	}

	subscribeEmail(body) {
		this.profileApi.clientEmailPreference(body).subscribe(() => {});
	}

	uploadUserProfilePhoto(uploadedPhoto: File): Observable<object> {
		this.profileState.setIsLoading(true);

		return this.profileApi.getAwsProfilePhotoUrl().pipe(
			catchError((error) => {
				this.snackService.open({
					data: { message: 'There was a problem uploading the image.', type: 'error' },
					panelClass: 'snackbar-error',
				});
				throw new Error(error);
			}),
			switchMap((response: { data: UploadPhotoResponse }) => {
				const awsUploadUrl = response.data?.uploadUrl;
				const blob = new Blob([uploadedPhoto], { type: uploadedPhoto.type });

				return this.profileApi.postUploadedPhoto(awsUploadUrl, blob).pipe(
					catchError((error) => {
						this.snackService.open({
							data: { message: 'There was a problem uploading the image.', type: 'error' },
							panelClass: 'snackbar-error',
						});
						throw new Error(error);
					}),
					finalize(() => {
						this.profileState.setIsLoading(false);
					}),
					map((res: null) => {
						this.snackService.open({
							data: { message: this.configState.getTranslations()['n122'], type: 'success' },
							panelClass: 'snackbar-success',
						});

						this.authService.update({ ...this.authService.user(), picture: `${environment.apiImageUrl}/${response.data.objectKey}` });

						this.updateUser({
							...this.profileState.getUser(),
							picture: `${environment.apiImageUrl}/${response.data.objectKey}`,
						});
						return res;
					})
				);
			})
		);
	}

	getUserAvatar(): void {
		this.profileState.setIsLoading(true);

		this.profileApi
			.getUserAvatar()
			.pipe(
				finalize(() => {
					this.profileState.setIsLoading(false);
				})
			)
			.subscribe((response: any) => {
				this.dialog.open(MyProfileChangeAvatarComponent, {
					width: '400px',
					height: 'auto',
					panelClass: 'avatar-dialog',
					data: {
						res: response,
					},
				});
			});
	}

	checkMobileNoExist(mobileNo): void {
		this.profileState.setIsLoading(true);

		this.profileApi
			.checkMobileNoExist(mobileNo)
			.pipe(
				catchError((error) => {
					this.snackService.open({
						data: { message: 'Please use another mobile number.', type: 'error' },
						panelClass: 'snackbar-error',
					});

					throw new Error(error);
				}),
				finalize(() => this.profileState.setIsLoading(false))
			)
			.subscribe(() => {});
	}

	updateUserAvatar(avatarUrl: string): void {
		this.profileState.setIsLoading(true);

		this.profileApi
			.updateUserAvatar({ url: avatarUrl })
			.pipe(
				finalize(() => {
					this.profileState.setIsLoading(false);
				})
			)
			.subscribe((response: any) => {
				if (response) {
					this.snackService.open({
						data: { message: this.configState.getTranslations()['n122'], type: 'success' },
						panelClass: 'snackbar-success',
					});

					this.updateUser({
						...this.profileState.getUser(),
						picture: avatarUrl,
					});

					this.authService.update({ ...this.authService.user(), picture: avatarUrl });
				}
			});
	}

	uploadUserBusinsessProfilePhoto(uploadedPhoto: File): Observable<any> {
		let photoUrl = '';

		this.profileState.setIsLoading(true);

		return this.profileApi.getAwsBusinsessProfilePhotoUrl().pipe(
			catchError((error) => {
				this.snackService.open({
					data: { message: 'There was a problem uploading the image.', type: 'error' },
					panelClass: 'snackbar-error',
				});
				throw new Error(error);
			}),
			switchMap((response: { objectKey: string; uploadUrl: string }) => {
				const AWS_UPLOAD_URL = response?.uploadUrl;
				const BLOB = new Blob([uploadedPhoto], { type: uploadedPhoto.type });
				photoUrl = response.objectKey;

				return this.profileApi.postUploadedPhoto(AWS_UPLOAD_URL, BLOB).pipe(
					catchError((error) => {
						this.snackService.open({
							data: { message: 'There was a problem uploading the image.', type: 'error' },
							panelClass: 'snackbar-error',
						});
						throw new Error(error);
					}),
					finalize(() => {
						this.profileState.setIsLoading(false);
					}),
					map(() => {
						this.snackService.open({
							data: { message: this.configState.getTranslations()['n3205'], type: 'success' },
							panelClass: 'snackbar-success',
						});

						this.updateUser({
							...this.profileState.getUser(),
							businessProfile: {
								...this.profileState.getUser()?.businessProfile,
								picture: `${environment.apiImageUrl}/${photoUrl}`,
							},
						});

						this.profileState.setBusinessProfile({
							...this.profileState.getBusinessProfile(),
							picture: `${environment.apiImageUrl}/${photoUrl}`,
						});
					})
				);
			})
		);
	}

	updateUserBusinsessProfile(businessProfile: UpdateBusinessProfileRequest): Promise<object> {
		return new Promise((resolve, reject) => {
			this.profileApi
				.updateBusinessProfile(businessProfile)
				.pipe(
					switchMap(() => this.profileApi.getBusinessProfile()),
					catchError((error) => {
						this.snackService.open({
							data: { message: this.configState.getTranslations()['n612'], type: 'error' },
							panelClass: 'snackbar-error',
						});
						reject(error);
						throw new Error(error);
					})
				)
				.subscribe((response) => {
					this.profileState.setBusinessProfile(response?.data);
					this.router.navigate([`/${APP_ROUTE.agentHub.dashboard}/${APP_ROUTE.businessProfile.base}`]);

					this.snackService.open({
						data: { translationKey: 'n4007', type: 'success', icon: 'check_circle' },
						panelClass: 'snackbar-success',
					});

					resolve({});
				});
		});
	}

	getProfileTabIndex$(): Observable<number> {
		return this.profileState.profileTabIndex$;
	}

	setProfileTabIndex(index: number): void {
		this.profileState.setProfileTabIndex(index);
	}

	loadAgents(): void {
		this.agentsApi.getAgents().subscribe((agentList: Agents[]) => {
			this.agentsState.setAgents(agentList);
		});
	}

	addAgents(emails: string[]): void {
		this.agentsApi
			.addAgents(emails)
			.pipe(
				switchMap(() => {
					this.snackService.open({
						data: { translationKey: 'n4006', icon: 'mark_email_read', type: 'success' },
						panelClass: 'snackbar-success',
					});

					return this.agentsApi.getAgents();
				}),
				catchError((error) => {
					this.snackService.open({
						data: { translationKey: 'n608', type: 'error' },
						panelClass: 'snackbar-error',
					});

					throw new Error(error);
				})
			)
			.subscribe((agents: Agents[]) => this.agentsState.setAgents(agents));
	}

	deleteAgent(agent: Agents): void {
		const IS_AGENT_LEAVING = agent?.userId === this.authService.user()?.id;
		this.agentsApi
			.deleteAgent(agent.id)
			.pipe(
				tap(() => {
					this.snackService.open({
						data: { translationKey: IS_AGENT_LEAVING ? 'n4068' : 'n3533', icon: 'check_circle', type: 'success' },
						panelClass: 'snackbar-success',
					});

					// Update user profile
					this.loadUser();
				}),

				catchError((error) => {
					this.snackService.open({
						data: { translationKey: 'n608', type: 'error' },
						panelClass: 'snackbar-error',
					});

					throw new Error(error);
				})
			)
			.subscribe(() => {
				if (IS_AGENT_LEAVING) {
					this.router.navigate([APP_ROUTE.myProfile]);
				}
			});
	}

	getAgents$(): Observable<Agents[]> {
		return this.agentsState.agents$;
	}

	canRemoveAgent(agentId: string): Promise<{ hasAssignedAd: string }> {
		return firstValueFrom(
			this.agentsApi.canRemoveAgent(agentId).pipe(
				catchError((error) => {
					this.snackService.open({
						data: { translationKey: 'n608', type: 'error' },
						panelClass: 'snackbar-error',
					});

					throw new Error(error);
				})
			)
		);
	}

	getAgents(): Agents[] {
		return this.agentsState.getAgents();
	}

	getBusinessProfile$(): Observable<BusinessProfile> {
		return this.profileState.businessProfile$;
	}

	getSubscriptionInfo$(): Observable<SubscriptionInfo> {
		return this.profileState.subscriptionInfo$.pipe(
			map((res) =>
				res
					? {
							...res,
							dates: {
								...res.dates,
								nextBilling: new Date(res?.dates?.nextBilling).getTime(),
								expired: new Date(res?.dates?.expired).getTime(),
							},
						}
					: null
			)
		);
	}

	getSubscriptionData(): SubscriptionInfo {
		return this.profileState.getSubscriptionData();
	}

	getSubscriptionInfo(): Observable<SubscriptionInfo> {
		return this.profileApi.getSubscriptionInfo().pipe(
			map((res) => res.data),
			tap((res) => this.profileState.setSubscriptionInfo(res))
		);
	}

	loadBusinessProfile(): void {
		this.profileApi
			.getBusinessProfile()
			.pipe(
				map((res: CommonResponse<BusinessProfile>) => res.data),
				switchMap((profile: BusinessProfile) => {
					this.profileState.setBusinessProfile(profile);

					if (profile?.name) {
						//to fetch agents of the user
						return combineLatest([this.agentsApi.getAgents(), this.getSubscriptionInfo()]);
					} else {
						return of({});
					}
				}),
				finalize(() => {
					this.profileState.setIsLoading(false);
				})
			)
			.subscribe(([agents]: [Agents[]]) => {
				if (agents instanceof Array) {
					this.agentsState.setAgents(agents);
				} else {
					this.agentsState.setAgents([]);
				}
			});
	}

	generatePaymentLink(body): Observable<PaymentLink> {
		return this.profileApi.generatePaymentLink(body).pipe(
			catchError((error) => {
				this.snackService.open({
					data: { translationKey: 'n608', type: 'error' },
					panelClass: 'snackbar-error',
				});

				throw new Error(error);
			}),
			map((res) => res.data)
		);
	}

	verifyPayment(body): Observable<object> {
		return this.profileApi.verifyPayment(body);
	}

	patchAgentPhone(agentId: string, body): Observable<object> {
		return this.profileApi.patchAgentPhone(agentId, body).pipe(
			catchError((err) => {
				this.snackService.open({
					data: { translationKey: 'n608', type: 'error' },
					panelClass: 'snackbar-error',
				});

				throw new Error(err);
			})
		);
	}

	cancelSubscription(body): Observable<object> {
		return this.profileApi.cancelSubscription(body).pipe(
			switchMap(() => this.getSubscriptionInfo()),
			catchError((error) => {
				this.snackService.open({
					data: { translationKey: 'n608', type: 'error' },
					panelClass: 'snackbar-error',
				});

				throw new Error(error);
			})
		);
	}
}
