<div class="container-fluid p-0">
	<div class="row px-3 align-items-center w-100 h-60px border-bottom">
		<div class="col">
			<h4 class="heading-4-regular fg-general-5">{{ 'n3968' | translations }}</h4>
		</div>
	</div>

	<div class="py-4 px-3">
		<p class="text-paragraph-regular fg-general-5">{{ 'n3969' | translations }}:</p>

		<div class="d-flex gap-1 align-items-center my-3">
			<div class="rounded-circle contact-icons">
				<mat-icon class="fg-prim-2 material-icons-outlined">mail_outlined</mat-icon>
			</div>

			<a class="text-body-medium fg-general-5" href="mailto:hello@findqo.ie">hello&#64;findqo.ie</a>
		</div>

		<div class="d-flex gap-1 align-items-center">
			<div class="rounded-circle contact-icons">
				<mat-icon class="fg-prim-2 material-icons-outlined">phone_outlined</mat-icon>
			</div>

			<a class="text-body-medium fg-general-5" href="tel:+35315314791">+353-15314791</a>
		</div>
	</div>

	<div class="border-top w-100 p-3">
		<button mat-button mat-dialog-close class="w-100 fg-prim-2 text-body-regular">{{ 'n97' | translations }}</button>
	</div>
</div>
