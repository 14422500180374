<div class="container p-3">
	<div class="col-auto px-0 text-end">
		<button mat-icon-button mat-dialog-close class="close-btn" (clicked)="onDismiss()">
			<mat-icon svgIcon="close-2" class="justify-content-center"></mat-icon>
		</button>
	</div>

	<div class="text-center">
		<p class="fs-16px lh-20px fw-600 fg-general-5">{{ 'n3108' | translations }}</p>
		<p class="fs-12px lh-16px fw-400 fg-general-3">{{ 'n516' | translations }}</p>
	</div>

	<div class="row mx-0 gap-4 mt-4 mb-3 align-items-center justify-content-center">
		<div class="col px-3 text-center" (click)="selectRadioButton(option.url)" *ngFor="let option of data.res?.data">
			<mat-radio-group [(ngModel)]="selectedAvatar" class="user-avatar">
				<mat-radio-button name="selectedOption" [value]="option.url"></mat-radio-button>
			</mat-radio-group>

			<img [src]="option.url" alt="Change profile picture" class="cursor-pointer mt-3 d-block mx-auto" />
		</div>
	</div>

	<findqo-button
		class="fg-info-3"
		[btnType]="buttonType.FILLED"
		[label]="'n517' | translations"
		[isStopPropagation]="true"
		[btnClass]="'w-100 fs-14px fw-400 lh-18px'"
		(clicked)="submitSelection()"
	></findqo-button>
</div>
