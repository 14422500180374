import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'findqo-map-icon',
	standalone: true,
	imports: [CommonModule],
	template: `
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="map">
				<path
					id="Vector"
					d="M15.375 2.25L15.255 2.2725L11.25 3.825L6.75 2.25L2.52 3.675C2.3625 3.7275 2.25 3.8625 2.25 4.035V15.375C2.25 15.585 2.415 15.75 2.625 15.75L2.745 15.7275L6.75 14.175L11.25 15.75L15.48 14.325C15.6375 14.2725 15.75 14.1375 15.75 13.965V2.625C15.75 2.415 15.585 2.25 15.375 2.25ZM7.5 4.1025L10.5 5.1525V13.8975L7.5 12.8475V4.1025ZM3.75 4.845L6 4.0875V12.8625L3.75 13.7325V4.845ZM14.25 13.155L12 13.9125V5.145L14.25 4.275V13.155Z"
					fill="#EE0088"
				/>
			</g>
		</svg>
	`,
	styleUrl: './map-icon.component.scss',
})
export class MapIconComponent {}
