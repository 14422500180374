import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AbstractAuthComponent } from '@feature/auth/abstracts/auth.abstract';
import { AuthFacade } from '@feature/auth/auth.facade';
import { VALIDATOR } from '@core/constants';

@Component({
	selector: 'findqo-welcome-v2',
	templateUrl: './welcome-v2.component.html',
	styleUrls: ['./welcome-v2.component.scss'],
})
export class WelcomeV2Component extends AbstractAuthComponent {
	constructor(
		private readonly fb: FormBuilder,
		private readonly authFacade: AuthFacade
	) {
		super();
	}

	form: FormGroup;

	onSubmit(): void {
		const NAME_CONTROL = this.form.get('name');
		// Update client name
		this.authFacade.updateClientName(NAME_CONTROL.value, this.changed, this.fromAgentInvitation);
	}

	private createForm(): void {
		this.form = this.fb.group({
			name: ['', [Validators.required, VALIDATOR.pattern.name]],
		});
	}

	ngOnInit(): void {
		this.createForm();
	}
}
