<form [formGroup]="form">
	<mat-card class="auth">
		<mat-card-header>
			<img ngSrc="assets/images/login.svg" alt="Login to website" width="217" height="175" />

			<h3>{{ 'n11' | translations }}</h3>

			<p>{{ 'n2329' | translations }}</p>

			<div class="features">
				<div *ngFor="let feature of featureList" class="features__item">
					<mat-icon svgIcon="check"></mat-icon>
					<h3 class="text-small-14px-regular fg-general-4 m-0">{{ feature | translations }}</h3>
				</div>
			</div>
		</mat-card-header>

		<mat-card-content>
			<findqo-input wrapperClass="no-subscript" formControlName="email" [placeholder]="'n2324' | translations" type="email"></findqo-input>

			<div class="mt-4 fs-12px fw-400 lh-16px fg-general-3 text-center">
				<p>{{ 'n3291' | translations }}</p>

				<a [href]="appRoute.terms" target="_blank">{{ 'n61' | translations }}</a>
				&
				<a [href]="appRoute.privacyPolicy" target="_blank">{{ 'n62' | translations }}</a>
			</div>
		</mat-card-content>

		<mat-card-actions>
			<findqo-button
				type="submit"
				[isLoading]="isLoading$ | async"
				[label]="'n3399' | translations"
				(clicked)="onRequestOtp()"
				[btnType]="buttonType.FILLED"
				[isDisabled]="form.invalid"
			></findqo-button>
		</mat-card-actions>
	</mat-card>
</form>
