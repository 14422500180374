import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'findqo-arrow-down-icon',
	standalone: true,
	imports: [CommonModule],
	template: `
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9.24356 13.8615C9.66194 14.2684 10.3414 14.2684 10.7598 13.8615L17.1862 7.61277C17.6046 7.20594 17.6046 6.54527 17.1862 6.13844C16.7678 5.73162 16.0884 5.73162 15.67 6.13844L10 11.6517L4.33002 6.1417C3.91164 5.73488 3.23218 5.73488 2.81379 6.1417C2.3954 6.54852 2.3954 7.2092 2.81379 7.61602L9.24021 13.8648L9.24356 13.8615Z"
				fill="#EE0088"
			/>
		</svg>
	`,
	styleUrl: './arrow-down-icon.component.scss',
})
export class ArrowDownIconComponent {}
