import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class MyProfileService {
	private readonly _inviterName$ = new BehaviorSubject<string>(undefined);

	get inviterName$(): Observable<string> {
		return this._inviterName$.asObservable();
	}

	setInviterName(name: string): void {
		return this._inviterName$.next(name);
	}
}
