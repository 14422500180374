import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { FiltersApi } from '@core/data-access/apis/filters.api';
import { ConfigState } from '@core/data-access/states/config.state';
import { ProfileState } from '@core/data-access/states/profile.state';
import { SectionsState } from '@core/data-access/states/sections.state';
import { LocaleType, SectionType } from '@core/enums';
import { Aisle, ClientUser, CommonItem, Config, RecentSearch, Section, Translations } from '@core/models';
import { BehaviorSubject, Observable, filter, map, tap } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CommonFacade {
	constructor(
		@Inject(PLATFORM_ID) private platformId: string,
		private readonly configState: ConfigState,
		private readonly profileState: ProfileState,
		private readonly sectionsState: SectionsState,
		private filterApi: FiltersApi
	) {}
	private _availiblitySlots$ = new BehaviorSubject<CommonItem[]>(null);
	private _recentSearches = new BehaviorSubject<RecentSearch[]>([]);

	/**
	 * Observables getter and setter
	 */

	get availiblitySlots$(): Observable<CommonItem[]> {
		return this._availiblitySlots$.asObservable();
	}

	get recentSearches$(): Observable<RecentSearch[]> {
		return this._recentSearches.asObservable();
	}

	getAvailiblitySlots(): CommonItem[] {
		return this._availiblitySlots$.getValue();
	}

	setAvailiblitySlots(timeSlots: CommonItem[]) {
		this._availiblitySlots$.next(timeSlots);
	}

	getSectionList$(): Observable<Section[]> {
		return this.sectionsState.sectionList$;
	}

	getTranslations$(): Observable<Translations> {
		return this.configState.translations$;
	}

	getLocale$(): Observable<LocaleType> {
		return this.configState.locale$;
	}

	getUser$(): Observable<ClientUser> {
		return this.profileState.user$;
	}

	getConfig$(): Observable<Config> {
		return this.configState.config$;
	}

	getAiseList$(section: string): Observable<Aisle[]> {
		return this.sectionsState.sectionList$.pipe(
			filter((sectionList: Section[]) => !!sectionList?.length),
			map(() => this.sectionsState.getSection(section).aisles)
		);
	}

	// end - Observables getter and setter

	getLocale(): LocaleType {
		return this.configState.getLocale();
	}

	setLocale(locale: LocaleType): void {
		if (!this.configState.getIsLoading()) {
			this.configState.setLocale(locale);
		}
	}

	getTranslations(): Translations {
		return this.configState.getTranslations();
	}

	getSection(section: SectionType): Section {
		return this.sectionsState.getSection(section);
	}

	loadAvailiblitySlots(): void {
		if (this.getAvailiblitySlots()?.length > 0) {
			return;
		} else {
			this.filterApi.getAvailibilitySlots().subscribe((timeSlots: CommonItem[]) => {
				this.setAvailiblitySlots(timeSlots);
			});
		}
	}

	fetchRecentSearch(): void {
		if (isPlatformBrowser(this.platformId)) {
			this.filterApi
				.getRecentSearch()
				.pipe(tap((res) => this._recentSearches.next(res.data)))
				.subscribe();
		}
	}

	getFormattedDate(timestamp: string) {
		const date = new Date(Number(timestamp) * 1000);
		return date.toLocaleDateString('en-US', {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		});
	}

	/**
	 * This is for changing the aisle order on home page
	 *
	 * TODO: Remove this as it's not needed anymore.
	 */
	// getSortedAisleList$(section: string): Observable<Aisle[]> {
	// 	return this.sectionsState.getSortedAisleList$(section);
	// }
}
