import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class AmplitudeService {
	constructor(@Inject(PLATFORM_ID) private platformId: string) {}

	updateUserId(email: string): void {
		if (isPlatformBrowser(this.platformId)) {
			if (window?.amplitude) {
				window.amplitude.setUserId(email);
			}
		}
	}
}
