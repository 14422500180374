import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@core/constants';
import {
	AgentInvitation,
	ClientUser,
	CommonResponse,
	CreateTenantProfileResponse,
	LoginRequest,
	LoginResponse,
	SendTenantOtp,
	TenantProfile,
	VerifyEmailInvitationData,
	VerifyEmailOtpRequest,
} from '@core/models';
import { SnackService } from '@core/services/snack.service';
import { Observable, catchError, map } from 'rxjs';
import { ConfigState } from '../states/config.state';

@Injectable({
	providedIn: 'root',
})
export class AuthApi {
	constructor(
		private readonly http: HttpClient,
		private readonly snackService: SnackService,
		private readonly configState: ConfigState
	) {}

	login(body: { phone: string; phoneCode: string }): Observable<CommonResponse<object>> {
		return this.http.post(API.auth.phone, body);
	}

	twilioSendOtp(request: LoginRequest): Observable<CommonResponse<Record<string, never>>> {
		return this.http.post<CommonResponse<Record<string, never>>>(`${API.auth.twilioSendOtp}`, request);
	}

	twilioVerifyOtp(request: LoginRequest): Observable<ClientUser> {
		return this.http.post<LoginResponse>(`${API.auth.twilioVerifyPhone}`, request).pipe(map((response: LoginResponse) => response.data));
	}

	requestEmailOtp(email: string): Observable<CommonResponse<Record<string, never>>> {
		return this.http.post<CommonResponse<Record<string, never>>>(`${API.auth.requestEmailOtp}`, { email }).pipe(
			catchError((response: HttpErrorResponse) => {
				// TODO: Request for the translations for client side error handling
				const ERROR_MESSAGE = response.error.error.errors[0].messages[0];

				this.snackService.open({
					data: { message: ERROR_MESSAGE },
					panelClass: ['snackbar-error'],
				});

				throw new Error(ERROR_MESSAGE);
			})
		);
	}

	verifyEmailOtp(request: VerifyEmailOtpRequest): Observable<CommonResponse<ClientUser>> {
		return this.http.post(`${API.auth.verifyEmailOtp}`, request).pipe(
			catchError(() => {
				this.snackService.open({
					data: { message: this.configState.getTranslations()['n699'] },
					panelClass: ['snackbar-error'],
				});

				throw new Error(this.configState.getTranslations()['n699']);
			})
		);
	}

	verifyEmailInvitation(agentInvitation: AgentInvitation): Observable<CommonResponse<VerifyEmailInvitationData>> {
		return this.http.post<{ data: VerifyEmailInvitationData }>(API.auth.verifyAgentInvitation, agentInvitation);
	}

	acceptAgentInvitation(agentInvitation: AgentInvitation): Observable<CommonResponse<ClientUser>> {
		return this.http.post(API.auth.acceptAgentInvitation, agentInvitation);
	}

	rejectAgentInvitation(agentInvitation: AgentInvitation): Observable<CommonResponse<ClientUser>> {
		return this.http.post(API.auth.rejectAgentInvitation, agentInvitation);
	}

	sendTenantOtp(body: SendTenantOtp): Observable<CommonResponse<object>> {
		return this.http.post(API.auth.sendTenantOtp, body);
	}

	verifyTenantOtp(request: VerifyEmailOtpRequest): Observable<CommonResponse<TenantProfile>> {
		return this.http.post(API.auth.verifyTenantOtp, request).pipe(
			catchError(() => {
				this.snackService.open({
					data: { message: this.configState.getTranslations()['n699'] },
					panelClass: ['snackbar-error'],
				});

				throw new Error(this.configState.getTranslations()['n699']);
			})
		);
	}

	//TODO: Add a appropriate type to the body, in future when we will pass something to body
	createTenantProfile(body: object = {}): Observable<CommonResponse<CreateTenantProfileResponse>> {
		return this.http.post<CommonResponse<CreateTenantProfileResponse>>(API.auth.createTenantProfile, body);
	}
}
