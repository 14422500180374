<form [formGroup]="form">
	<mat-card class="auth mt-sm-3">
		<mat-card-header>
			<img ngSrc="assets/images/welcome.svg" alt="Welcome Image" width="217" height="175" />

			<h3>{{ 'n2317' | translations }}</h3>

			<p>{{ 'n2318' | translations }}</p>
		</mat-card-header>

		<mat-card-content>
			<findqo-input wrapperClass="no-subscript" formControlName="name" type="text" [placeholder]="'n268' | translations" minlength="3"></findqo-input>
		</mat-card-content>

		<mat-card-actions>
			<findqo-button
				type="submit"
				[btnType]="buttonType.FILLED"
				[label]="'n269' | translations"
				(clicked)="onSubmit()"
				[isDisabled]="form.invalid"
			></findqo-button>
		</mat-card-actions>
	</mat-card>
</form>
