import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AbstractAuthComponent } from '@feature/auth/abstracts/auth.abstract';
import { AuthFacade } from '@feature/auth/auth.facade';
import { Observable, map, takeWhile, timer } from 'rxjs';
import { AuthDialogV2Component } from '../../auth-dialog-v2.component';
import { MatDialogRef } from '@angular/material/dialog';
import { MyProfileFacade } from '@feature/my-profile/my-profile.facade';

@Component({
	selector: 'findqo-delete-account-otp-v2',
	templateUrl: './delete-account-otp-v2.component.html',
	styleUrls: ['./delete-account-otp-v2.component.scss'],
})
export class DeleteAccountOtpV2Component extends AbstractAuthComponent implements OnInit {
	constructor(
		private readonly fb: FormBuilder,
		private readonly authFacade: AuthFacade,
		private readonly dialogRef: MatDialogRef<AuthDialogV2Component>,
		private myProfileFacade: MyProfileFacade
	) {
		super();
		this.isLoading$ = this.authFacade.isLoading$();
	}

	form: FormGroup;
	isLoading$: Observable<boolean>;
	otpTimeRemaining$: Observable<number>;
	readonly startTime = 45;

	async onVerifyEmail(): Promise<void> {
		const PIN = this.form.value.pin;
		this.myProfileFacade.deleteProfile({ pin: PIN }, this.dialogRef);
	}

	onResendOtp(): void {
		this.startTimer();
		this.authFacade.requestEmailOtp(this.email);
	}

	private createForm(): void {
		this.form = this.fb.group({
			pin: ['', [Validators.required, Validators.minLength(6), Validators.pattern('[0-9]+')]],
		});
	}

	private startTimer(): void {
		this.otpTimeRemaining$ = timer(0, 1000).pipe(
			map((time: number) => (this.startTime - time) * 1000),
			takeWhile((time: number) => time >= 0)
		);
	}

	ngOnInit(): void {
		this.createForm();
		this.startTimer();
	}
}
