import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as EmailValidator from 'email-validator';

export class AppEmailValidator {
	/**
	 * Custom validator for email. Angular email pattern validation is not efficient.
	 */
	static validateEmailPattern = (): ValidatorFn => {
		return (control: AbstractControl): { [key: string]: boolean } | null => {
			if (control?.value && !EmailValidator.validate(control.value)) {
				return { emailPattern: true };
			}

			return null;
		};
	};
}
