<div class="interest-form">
	<div class="pb-4 px-3 px-sm-0">
		<h3 class="heading-3-medium fg-general-5">{{ 'n3979' | translations }}</h3>
		<p class="text-body-regular fg-general-5 mb-4 mt-2">{{ 'n3980' | translations }}.</p>

		<mat-selection-list #interestList class="p-0" (selectionChange)="handleSelection($event)">
			@for (interest of interests; track interest; let i = $index) {
				<mat-list-option
					class="border text-body-regular fg-general-5"
					[value]="interest"
					color="primary"
					[selected]="false"
					[ngClass]="{ 'mb-3': i < interests.length - 1, selected: +this.form.get([interest.id]).value }"
				>
					{{ interest.name | translations }}
				</mat-list-option>
			}
		</mat-selection-list>
	</div>

	<div class="bottom-container w-100">
		<button mat-button class="p-0 text-caption-regular fg-general-4 mb-4 ms-3 ms-sm-0 h-14px" (click)="ignoreSurvey()">{{ 'n3981' | translations }}</button>

		<div class="pt-3 px-3 border-top -mx-3">
			<button
				mat-flat-button
				color="primary"
				class="w-100 h-48px text-body-regular"
				[disabled]="!interestList.selectedOptions.selected.length"
				(click)="submitSurvey()"
			>
				{{ 'n91' | translations }}
			</button>
		</div>
	</div>
</div>
