import { Component } from '@angular/core';

@Component({
	selector: 'findqo-chevron-icon',
	standalone: false,
	template: `
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.4754 8.3192C12.8415 8.69575 12.8415 9.30726 12.4754 9.68381L6.85149 15.4676C6.48535 15.8441 5.89074 15.8441 5.5246 15.4676C5.15847 15.091 5.15847 14.4795 5.5246 14.103L10.4865 9L5.52753 3.89702C5.16139 3.52047 5.16139 2.90896 5.52753 2.53241C5.89367 2.15586 6.48828 2.15586 6.85442 2.53241L12.4783 8.31619L12.4754 8.3192Z"
				fill="#A6AAB3"
			/>
		</svg>
	`,
	styleUrl: './chevron-icon.component.scss',
})
export class ChevronIconComponent {}
