import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthType } from '@core/enums';
import { ButtonType } from '@shared/components/button/button.component';

@Component({
	template: '',
})
export abstract class AbstractAuthComponent {
	@Input() email: string;
	@Input() authType: AuthType;
	@Input() fromAgentInvitation?: boolean;
	@Input() isPostAd = false;

	@Output() changed = new EventEmitter();

	readonly buttonType = ButtonType;
}
