import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MyProfileFacade } from '@feature/my-profile/my-profile.facade';
import { Observable } from 'rxjs';
import { Translations } from '@core/models/translations.model';
import { ButtonType } from '@shared/components/button/button.component';

@Component({
	selector: 'findqo-my-profile-change-avatar',
	templateUrl: './my-profile-change-avatar.component.html',
	styleUrls: ['./my-profile-change-avatar.component.scss'],
})
export class MyProfileChangeAvatarComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<MyProfileChangeAvatarComponent>,
		private myProfileFacade: MyProfileFacade
	) {
		this.user$ = myProfileFacade.getUser$();
		this.translations$ = this.myProfileFacade.getTranslations$();
	}

	selectedAvatar: string;
	user$: Observable<any>;
	translations$: Observable<Translations>;
	readonly buttonType = ButtonType;

	submitSelection() {
		if (this.selectedAvatar) {
			this.myProfileFacade.updateUserAvatar(this.selectedAvatar);
		}
		this.dialogRef.close(false);
	}

	selectRadioButton(url: string) {
		this.selectedAvatar = url;
	}

	onDismiss(): void {
		this.dialogRef.close(false);
	}

	ngOnInit(): void {
		this.user$.subscribe((res) => {
			this.selectedAvatar = res?.picture;
		});
	}
}
