<findqo-button class="close mx-0 position-absolute" iconSuffix="arrow-back" [btnType]="buttonTypeEnum.FILLED" (clicked)="onChangeEmail()"></findqo-button>

<form [formGroup]="form">
	<mat-card class="auth">
		<mat-card-header>
			<img ngSrc="assets/images/email.svg" alt="Change email otp" width="217" height="175" />

			<h3>{{ showHeading }}</h3>

			<p>{{ 'n2319' | translations }}</p>

			<span clas="text-small-14px-regular fg-general-5">{{ email }}</span>
		</mat-card-header>

		<mat-card-content>
			<!-- OTP input -->
			<findqo-input
				wrapperClass="no-subscript"
				inputClass="text-center text-body-20px-medium"
				formControlName="pin"
				[maxLength]="6"
				type="text"
				pattern="\d*"
				[placeholder]="'n3232' | translations"
			></findqo-input>
		</mat-card-content>

		<mat-card-actions>
			<findqo-button
				type="submit"
				[btnType]="buttonType.FILLED"
				[label]="'n2321' | translations"
				[isLoading]="isLoading$ | async"
				(clicked)="onVerifyEmail()"
				[isStopPropagation]="true"
				[isDisabled]="form.controls['pin'].pristine || form.invalid"
			></findqo-button>

			<div class="w-100 timer">
				<ng-container *ngIf="otpTimeRemaining$ | async as otpTimeRemaining; else resendCode">
					<span class="text-body-14px-regular fg-general-4 lh-1">
						{{ 'n557' | translations }}
						{{ otpTimeRemaining >= startTime * 1000 ? startTime : (otpTimeRemaining | date: 'ss') }}
					</span>
				</ng-container>

				<ng-template #resendCode>
					<div class="w-100 text-center">
						<findqo-button
							[label]="'n3049' | translations"
							materialIconPrefix="help_outline"
							[btnType]="buttonType.STROKED"
							(clicked)="showIntercom()"
						></findqo-button>

						<findqo-button
							class="resend lh-1 mt-3"
							type="reset"
							[label]="'n271' | translations"
							[btnType]="buttonType.BASIC"
							[btnClass]="'text-body-regular'"
							(clicked)="onResendOtp()"
						></findqo-button>
					</div>
				</ng-template>
			</div>

			<div class="text-center" *ngIf="authType !== authTypeEnum.UPDATE_EMAIL_OTP">
				<span class="text-body-14px-regular fg-general-4">{{ 'n4069' | translations }}?</span>

				<findqo-button
					class="change-email ms-1"
					btnClass="p-0"
					[label]="'n60' | translations"
					[btnType]="buttonType.BASIC"
					(clicked)="showIntercom()"
				></findqo-button>
			</div>
		</mat-card-actions>
	</mat-card>
</form>
