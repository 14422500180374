import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@core/constants';
import { Agent, AgentLandingResponse, AgentPageMetaData, AgentSignUpBody, Agents, CommonResponse, Seller } from '@core/models';
import { Observable, map, tap } from 'rxjs';
import { AgentsState } from '../states/agents.state';
import { Params } from '@angular/router';
import { CommonUtil } from '@core/utils/common.util';

@Injectable({
	providedIn: 'root',
})
export class AgentsApi {
	constructor(
		private http: HttpClient,
		private agentState: AgentsState
	) {}

	getAgents(params?: Params): Observable<Agents[]> {
		return this.http.get<{ data: Agents[]; meta: AgentPageMetaData }>(API.agents.base, { params: CommonUtil.createHttpParams(params) }).pipe(
			tap((response) => {
				this.agentState.setAgentsMetaData(response.meta);
			}),
			map((response) => response.data)
		);
	}

	//Note the response here we are gettign is temporary response.Once Api gets updated we need to update the response here
	addAgents(emails: string[]): Observable<CommonResponse<[]>> {
		return this.http.post<CommonResponse<[]>>(API.agents.base, { email: emails });
	}

	deleteAgent(agentId: string): Observable<CommonResponse<[]>> {
		return this.http.delete(API.agents.updateAgent(agentId));
	}

	canRemoveAgent(agentId: string): Observable<{ hasAssignedAd: string }> {
		return this.http.post<CommonResponse<{ hasAssignedAd: string }>>(API.agents.agentHasAd(agentId), {}).pipe(map((response) => response?.data));
	}

	getAgentList(action?: string): Observable<Agent[]> {
		return this.http
			.get(API.agents.base, {
				params: {
					status: '2',
					action,
				},
			})
			.pipe(map((response: { data: Agent[] }) => response?.data));
	}

	agentSignUp(body: AgentSignUpBody): Observable<object> {
		return this.http.post(API.agents.signUp, body);
	}

	getBusinessList(): Observable<Seller[]> {
		return this.http.get<CommonResponse<Seller[]>>(API.agents.business).pipe(map((res) => res.data));
	}

	getList(params?: { page: number }): Observable<any> {
		return this.http.get<CommonResponse<AgentLandingResponse>>(API.agents.list, { params: params }).pipe(map((res) => res));
	}
}
