<div class="dialog-container position-relative">
	<findqo-button
		*ngIf="currentAuthType !== authTypeEnum.WELCOME"
		class="close"
		iconSuffix="close-2"
		[btnType]="buttonTypeEnum.FILLED"
		(clicked)="onClose()"
	></findqo-button>

	<ng-container #container></ng-container>

	<button cdkFocusInitial hidden></button>
</div>
