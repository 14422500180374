<mat-card class="auth">
	<mat-card-header>
		<div class="text-center">
			<img ngSrc="/assets/images/update-email.svg" alt="Update Account Email" width="217" height="175" class="text-center" />

			<h3 class="fg-general-5 mb-3">{{ 'n92' | translations }}</h3>

			<p class="text-small-14px-regular fw-400 lh-18px fg-general-4 mb-2">{{ 'n3107' | translations }}</p>

			<span class="text-small-14px-regular fg-general-5 d-block">{{ email }}</span>
		</div>
	</mat-card-header>

	<mat-card-actions>
		<findqo-button class="w-100" [label]="'n91' | translations" [btnType]="buttonType.FILLED" (clicked)="onSubmit()"></findqo-button>
	</mat-card-actions>
</mat-card>
