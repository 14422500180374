import { getLocaleDirection } from '@angular/common';
import { Injectable } from '@angular/core';
import { LocaleType } from '@core/enums';
import { Config, Translations } from '@core/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ConfigState {
	private readonly _locale$ = new BehaviorSubject<LocaleType>(LocaleType.ENGLISH);
	private readonly _translations$ = new BehaviorSubject<Translations>(undefined);
	private readonly _isLoading$ = new BehaviorSubject<boolean>(false);
	private readonly _config$ = new BehaviorSubject<Config>(undefined);

	get config$(): Observable<Config> {
		return this._config$.asObservable();
	}

	get locale$(): Observable<LocaleType> {
		return this._locale$.asObservable();
	}

	get translations$(): Observable<Translations> {
		return this._translations$.asObservable();
	}

	get isLoading$(): Observable<boolean> {
		return this._isLoading$.asObservable();
	}

	getLocale(): LocaleType {
		return this._locale$.getValue();
	}

	setLocale(locale: LocaleType): void {
		this._locale$.next(locale);
	}

	getLocaleDirection(locale: LocaleType): string {
		return getLocaleDirection(locale);
	}

	setTranslations(translations: Translations): void {
		this._translations$.next(translations);
	}

	setIsLoading(isLoading: boolean): void {
		this._isLoading$.next(isLoading);
	}

	getIsLoading(): boolean {
		return this._isLoading$.getValue();
	}

	getTranslations(): Translations {
		return this._translations$.getValue();
	}

	setConfig(config: Config): void {
		this._config$.next(config);
	}
}
