import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HeaderFacade } from '@core/components/header/header.facade';
import { AuthType } from '@core/enums';
import { CommonFacade } from '@core/facades/common.facade';
import { ClientUser } from '@core/models';
import { AppEmailValidator } from '@core/validators';
import { AbstractAuthComponent } from '@feature/auth/abstracts/auth.abstract';
import { AuthFacade } from '@feature/auth/auth.facade';
import { Observable } from 'rxjs';

@Component({
	selector: 'findqo-new-email-v2',
	templateUrl: './new-email-v2.component.html',
	styleUrls: ['./new-email-v2.component.scss'],
})
export class NewEmailV2Component extends AbstractAuthComponent implements OnInit {
	constructor(
		private readonly fb: FormBuilder,
		private readonly authFacade: AuthFacade,
		private readonly headerFacade: HeaderFacade,
		private readonly commonFacade: CommonFacade
	) {
		super();
		this.isLoading$ = this.authFacade.isLoading$();
	}

	isLoading$: Observable<boolean>;
	form: FormGroup;
	user$: Observable<ClientUser>;
	userEmailId: string;

	onRequestOtp(): void {
		const EMAIL = this.form.get('email').value;

		// Requet email OTP
		this.authFacade.updateEmailOtp(EMAIL).then(() => {
			// Go to OTP screen
			this.changed.emit({
				authType: AuthType.NEW_EMAIL_OTP,
				email: EMAIL,
			});
		});
	}

	private createForm(): void {
		this.form = this.fb.group({
			email: ['', [Validators.required, AppEmailValidator.validateEmailPattern()]],
		});
	}

	ngOnInit(): void {
		this.createForm();
		this.user$ = this.headerFacade.getUser$().subscribe((user: ClientUser) => {
			this.userEmailId = user?.email;
		});
	}
}
