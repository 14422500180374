import { Injectable } from '@angular/core';
import { AgentPageMetaData, Agents } from '@core/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AgentsState {
	private readonly _agents$ = new BehaviorSubject<Agents[]>(undefined);
	private readonly _agentsMetaData$ = new BehaviorSubject<AgentPageMetaData>(undefined);

	get agents$(): Observable<Agents[]> {
		return this._agents$.asObservable();
	}

	get agentsMetaData$(): Observable<AgentPageMetaData> {
		return this._agentsMetaData$.asObservable();
	}

	getAgentsMetaData(): AgentPageMetaData {
		return this._agentsMetaData$.getValue();
	}

	setAgentsMetaData(data: AgentPageMetaData): void {
		this._agentsMetaData$.next(data);
	}

	getAgents(): Agents[] {
		return this._agents$.getValue();
	}

	setAgents(data: Agents[]): void {
		this._agents$.next(data);
	}
}
