import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ProfileApi } from '@core/data-access/apis/profile.api';
import { AuthService } from '@core/data-access/services/auth.service';
import { ConfigState } from '@core/data-access/states/config.state';
import { ProfileState } from '@core/data-access/states/profile.state';
import { SectionsState } from '@core/data-access/states/sections.state';
import { LocaleType } from '@core/enums/locale.enum';
import { ClientUser } from '@core/models';
import { Translations } from '@core/models/translations.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class HeaderFacade {
	constructor(
		private readonly profileApi: ProfileApi,
		private readonly authService: AuthService,
		private readonly configState: ConfigState,
		private readonly profileState: ProfileState,
		private readonly sectionsState: SectionsState
	) {}

	private readonly _isLoading$ = new BehaviorSubject<boolean>(false);

	getIsLoading$(): Observable<boolean> {
		return this._isLoading$.asObservable();
	}

	getLocale$(): Observable<LocaleType> {
		return this.configState.locale$;
	}

	getTranslations$(): Observable<Translations> {
		return this.configState.translations$;
	}

	setIsLoading(isLoading: boolean): void {
		this._isLoading$.next(isLoading);
	}

	getUser$(): any {
		return this.profileState.user$;
	}

	getUser(): ClientUser {
		return this.profileState.getUser();
	}

	updateUserName(name: string): void {
		this.authService.update({ ...this.authService.user(), name: name });
	}

	logout(): void {
		this.authService.logout();
	}

	setLocale(locale: LocaleType): void {
		if (!this.configState.getIsLoading()) {
			this.configState.setLocale(locale);
		}
	}

	openAuthDialog(): void {
		this.authService.openAuthDialog({ facade: this });
	}

	loadUser(): Promise<{}> {
		return new Promise((resolve) => {
			this.profileApi.getProfile().subscribe((response: any) => {
				this.profileState.updateUser(response.data);
				resolve({});
			});
		});
	}
}
